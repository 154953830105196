
import { Component, Vue, Prop } from "vue-property-decorator";
@Component
export default class BaseDataTable extends Vue {
  @Prop(Array) headers: any;
  @Prop(Array) items: any;
  @Prop(String) search: string;
  @Prop(Boolean) isLeadsList: boolean;
  @Prop(Boolean) isSearch: boolean;
  @Prop(Object) me: any;
}
